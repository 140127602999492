

export const SET_ITEMS = "SET_ITEMS";
export const SET_MENU = "SET_MENU";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";


const setItems = (data, state) => {
   
    return { ...state, cart: data };
  };
  
  const setMenu = (data, state) => {
   
    return { ...state, menu: data };
  };


const addProductToCart = (product, state) => {
  console.log("adding product", product);
 //console.log(state)
  let cart = {...state.cart};
   
  
   
    return { ...state, cart: cart };
  
};

const removeProductFromCart = (productId, state) => {
  console.log("remove product: " + productId);
  const updatedCart = [...state.cart];
  const updatedItemIndex = updatedCart.findIndex(item => item.id === productId);

  const updatedItem = {
    ...updatedCart[updatedItemIndex]
  };
  updatedItem.quantity--;
  if (updatedItem.quantity <= 0) {
    updatedCart.splice(updatedItemIndex, 1);
  } else {
    updatedCart[updatedItemIndex] = updatedItem;
  }

  return { ...state, cart: updatedCart };
};

export const shopReducer = (state, action) => {
  switch (action.type) {
    case SET_ITEMS:
      return setItems(action.data, state);
    
    case ADD_PRODUCT:
        return addProductToCart(action.product, state)
      
    case REMOVE_PRODUCT:
      return removeProductFromCart(action.productId, state);

    default:
      return state;
  }
};

export const pageReducer = (state, action) => {
  switch (action.type) {
    case SET_MENU:
      return setMenu(action.data, state);
     
    default:
      return state;
  }
};